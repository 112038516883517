import {alpha, darken, styled} from "@mui/material";

export const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: 'white',
    fontWeight: 'bold',
    backgroundColor:
        theme.palette.mode === 'light'
            ? theme.palette.accent.main
            : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
    padding: 0,
});