import React, {useContext, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {Button, Card, Container, createTheme, Snackbar, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/images/logo_bicolor_1000px/Logo winston - centered - rosso bianco@1K.png'
import authService from "../services/auth.service";
import {Alert} from "@mui/lab";
import {ArrowForward} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import {auth} from "../firebase/clientApp";
import {GlobalContext} from "../state/global";
import {setAuthState, setSelectedRoute} from "../state/global/globalActions";
import jwt_decode from "jwt-decode";

const Login = () => {
    document.body.style.backgroundColor = customTheme.palette.secondary.main;
    const sx = {
        "& input": {
            textAlign: "center",
            color: "white",
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
        },
    }

    const [globalState, dispatch] = useContext(GlobalContext)

    let navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        auth.signInWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
                const token = await userCredential?.user?.getIdToken()

                if (token) {
                    const role = jwt_decode(token)?.role
                    dispatch(setAuthState({isAdmin: role === 'ADMIN'}))
                }

                navigate('/', {replace: true})
                //window.location.reload();
            })
            .catch((error) => {
                //console.log("error:",error)
                setLoading(false);
                setMessage("Ops, login non andato a buon fine...");
            });
    }

    const handleCloseError = () => setMessage("");

    return(
        <main>
            <Container sx={{textAlign:'center',paddingTop:'2rem'}}>
                <img src={logo} width={'28%'} style={{margin:'1rem'}}/>
                <Card style={{height:'100%', textAlign:'right'}} variant={'login'}>
                    <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                        <Typography variant={'h5'} color={'white'}>Accedi</Typography>
                        <TextField style={{maxWidth:'31.25rem'}} sx={{...sx, my:2}} size={'small'} fullWidth placeholder={'Email'} onChange={handleSetEmail}/>
                        <TextField style={{maxWidth:'31.25rem'}} sx={{...sx, mb:2}} size={'small'} fullWidth placeholder={'Password'} type={"password"} onChange={handleSetPassword}/>
                        <Button onClick={handleLogin}
                                variant={"menu-selected"}
                                style={{width: '35%', fontSize:'1rem'}}>
                            Login
                        </Button>
                    </Stack>
                </Card>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default Login
