import {
    AccessTime,
    AlternateEmail,
    CheckBox,
    CheckBoxOutlineBlank,
    DateRange,
    HorizontalRule,
    Info,
    Link,
    MenuOpen, MonetizationOn,
    Notes, Numbers,
    QrCodeScanner,
    RadioButtonChecked,
    TextFields,
    Today,
    Tune
} from "@mui/icons-material";
import React from "react";
import {camelCase, replace} from "lodash";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    NUMBER: {icon: <Numbers color={'disabled'}/>, type: "number", label: "Numero", admin: false},
    PRICE: {icon: <MonetizationOn color={'disabled'}/>, type: "price", label: "Prezzo", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
    DIVIDER: {icon: <HorizontalRule color={'disabled'}/>, type: "divider", label: "", admin: true, noDataGrid: true},
}

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getId(fieldName) {
    return fieldName ? camelCase(fieldName.trim()
        .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
        .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
    return value ?
        replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
        : "";
}

export const seasons = {
    "2024": {label: "2024/2025", sigla: "2024"},
    "2023": {label: "2023/2024", sigla: "2023"},
}

export const categories = [
    {id: "assoluti", label: "Assoluti", sigla: "ASS", stagione: ""},
    {id: "running3mesi", label: "RUNNING TEAM - 3 MESI", sigla: "RT3", stagione: ""},
    {id: "running5mesi", label: "RUNNING TEAM - 5 MESI", sigla: "RT5", stagione: ""},
    {id: "running10mesi", label: "RUNNING TEAM - 10 MESI", sigla: "RT10", stagione: ""},

    {id: "esordientiPalestra", label: "ESO PALESTRA: 2019–2018–2017", sigla: "ESO PAL", stagione: "2024"},
    {id: "esordientiCampo", label: "ESO CAMPO: 2016–2015–2014", sigla: "ESO CAMPO", stagione: "2024"},
    {id: "ragazzi", label: "Ragazzi/e: 2013 - 2012", sigla: "RAG", stagione: "2024"},
    {id: "cadetti", label: "Cadetti/e: 2011 - 2010", sigla: "CAD", stagione: "2024"},

    {id: "esordienti1", label: "Esordienti C: 2017 - 2016", sigla: "ESO C", stagione: "2023"},
    {id: "esordienti2", label: "Esordienti B: 2015 - 2014", sigla: "ESO B", stagione: "2023"},
    {id: "esordienti3", label: "Esordienti A: 2013 - 2012", sigla: "ESO A", stagione: "2023"},
    {id: "ragazzi", label: "Ragazzi/e: 2011 - 2010", sigla: "RAG", stagione: "2023"},
    {id: "cadetti", label: "Cadetti/e: 2009 - 2008", sigla: "CAD", stagione: "2023"},
]

export const subscriptionTypes = [
    {label: "Monosettimanale martedì", sigla: "MAR"},
    {label: "Monosettimanale giovedì", sigla: "GIO"},
    {label: "Completo", sigla: null},
    {label: "Bisettimanale", sigla: null},
]

export const capitalizeFirst = (inputStr) =>  {
    if(inputStr) {
        inputStr = inputStr.trim()
        return inputStr[0].toUpperCase() + inputStr.slice(1).toLowerCase()
    }
    return ""
}