import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCRi_IECCXmDs6rg14hK9wIqdM3VsriwwM",
    authDomain: "atletica-cernusco.firebaseapp.com",
    projectId: "atletica-cernusco",
    storageBucket: "atletica-cernusco.appspot.com",
    messagingSenderId: "1023068824720",
    appId: "1:1023068824720:web:7b5461aad3595ae424e415"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}