import React, {createRef} from "react";
import _ from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {ContentCopy, Download, FileOpen, OpenInNew, VideoCameraFront} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {API_URL} from "../config";
import CustomTooltip from "./CustomTooltip";
import {customTheme} from "../theme/customTheme";
import {useParams} from "react-router-dom";
import ImgPopUpComponent from "./ImgPopUpComponent";
import {saveAs} from 'file-saver'
import AuthService from "../services/auth.service";
import {auth} from "../firebase/clientApp";

const CustomField = ({userId, formik, field, disabled}) => {
    const {id: user_id} = useParams()
    const fieldType = _.find(fieldTypes, ['type', field.type])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? ["Milano Centrale", "Milano Garibaldi"]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.cols?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label || field.headerName}</Typography>
                                   <RadioGroup
                                       row
                                       value={getValue()}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio.value} value={radio.value} control={
                                                   <Radio required/>} label={radio.label}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getOtherValue()}
                                               onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                               </FormControl>
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               disabled={field.precompiled || disabled}
                                               checked={typeof getValue() === "string" ? getValue() === 'si' : getValue()}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   if (!field.precompiled && !disabled) {
                                                       if(typeof getValue() === "string") {
                                                           formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked ? 'si' : 'no')
                                                           formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                                       } else {
                                                           formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked)
                                                           formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                                       }
                                                   }
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                       //value={dati[field.id]}
                                       value={getValue()}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {(field?.values || getOptions()).map(v =>
                                           <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(getValue()?._seconds ?
                                           getValue()._seconds * 1000
                                           : getValue()
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>
                           case fieldTypes.DATE_TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDateTimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(getValue()?._seconds ?
                                           getValue()._seconds * 1000
                                           : getValue()
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy, HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <TimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, date)
                                               formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(getValue()) || ''}
                                       inputFormat="HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.URL:
                               return getValue() ?
                                   <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={getValue()}
                                                             target={'_blank'}
                                                             color={'accent'}
                                                             size={'small'}
                                                             variant={'contained'}
                                                             sx={{paddingBlock: 1, color: 'white'}}
                                                             onClick={(event) => event.stopPropagation()}
                                                             endIcon={<FileOpen/>}>
                                               {field.label}
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(getValue())
                                                                 }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : null
                           {/*<Button variant={"outlined"}
                                                    endIcon={<OpenInNew/>}
                                                    onClick={() => window.open(`https://iscrizioni.atleticacernusco.it/upload/${user_id}`, '_blank')}>
                                       Vai alla pagina di caricamento documenti
                                   </Button>*/}
                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={2}
                                                 maxRows={3}
                                                 value={getValue()}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"outlined"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.TEXT:
                           case fieldTypes.NUMBER:
                               let val = null
                               if (field.id === 'id')
                                   val = user_id

                               if(field.getter === 'tutore') {
                                   console.log(field.getter ? `${field.getter}.${field.id}` : field.id)
                               }

                               return <TextField id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 disabled={field.precompiled || disabled}
                                                 value={val || getValue()}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>

                           case fieldTypes.PRICE:
                               const parsedPrice = getValue()?.toString()?.slice(0, getValue()?.toString()?.length-2)
                               const parsedCents = getValue()?.toString()?.slice(getValue()?.toString()?.length-2, getValue()?.toString()?.length)
                               let price = `${parsedPrice},${parsedCents} €`
                               price = getValue() ? price : '0 €'

                               return <TextField id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 //disabled={field.precompiled || disabled}
                                                 disabled={true}
                                                 value={price}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>

                           case fieldTypes.DIVIDER:
                               return <Divider
                                   children={field?.label && <Chip size={'small'} label={field?.label || ''}/>}/>

                           default:
                               return null
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField