import React from "react";
import _, {filter, find, includes, map, replace, split} from 'lodash'
import {capitalizeFirst, fieldTypes, parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, Chip, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import ImgPopUpComponent from "../components/ImgPopUpComponent";
import {Check, Close, ContentCopy, Link, OpenInNew, VideoCameraFront} from "@mui/icons-material";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId||''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns}, )
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}}, )
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.title})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form) => {
    const cols = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field?.onlyUserForm && !field?.noDataGrid
    })?.map((field) => {
        function getValue(field, params) {
            const id = (includes(field.id, '.')) ? split(field.id, '.')[1] : field.id
            return field?.getter ? params.row[field.getter]?.[id] : params.row[id]
        }

        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
            minWidth: 100
        }

        baseField = field?.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }
        } : {...baseField, valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        if(!formType?.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleTimeString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì') {
                                return 'si';
                            } else if (getValue(field, params) === false || getValue(field, params) === 'false' || !getValue(field, params) || getValue(field, params) === 'no')
                                return 'no';
                            else return getValue(field, params) || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'true' || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'false' || params.value === 'no' || !params.value)
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (getValue(field, params) === false || getValue(field, params) === 'false' || getValue(field, params) === 'no' || getValue(field, params) === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                case fieldTypes.URL:
                    return {
                        ...baseField,
                        width: 25,
                        flex: 0,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                {/*<CustomTooltip title={"Copia link"}
                                    children={<IconButton size={'small'} color={'primary'}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>}
                                />*/}
                                <CustomTooltip
                                    title={'Apri link in un\'altra finestra'}
                                    children={
                                        <IconButton size={'small'} color={'primary'}
                                                    href={params.value}
                                                    target={'_blank'}
                                                    onClick={(event) => event.stopPropagation()}>
                                            <OpenInNew fontSize={'small'}/>
                                        </IconButton>}/>
                            </Box> : ""
                    }
                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        disableExport: true,
                        renderCell: (params) => {
                            /** CAMBIARE streaming */
                            if (params.row.partecipazione === 'streaming')
                                return <CustomTooltip title={"Copia link streaming"} children={
                                    <Button
                                        color={'accent'}
                                        onClick={async (event) => {
                                            event.stopPropagation()
                                            await navigator.clipboard.writeText(`https://believetoachieve2023.canon.it/live/${params.id}`)
                                        }}>
                                        <Link/>
                                    </Button>
                                }/>
                            else if (params.row.partecipazione === 'si')
                                return !!params.row.qrCodeUrl ?
                                    <ImgPopUpComponent qrCodeUrl={params.row.qrCodeUrl}/>
                                    : <Chip label={'No QR Code'} size={'small'}/>
                            else if (params.row.partecipazione === 'no')
                                return '-'
                        }
                    }
                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === 'acconsento') {
                                return 'si';
                            } else if (params.value === 'nonAcconsento')
                                return 'no';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'acconsento') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'nonAcconsento')
                                return 'No';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        renderCell: (params) => {
                            return (params.value === true
                                || params.value?.toLowerCase() === 'si' || params.value?.toLowerCase() === 'sì'
                                || params.value === 'acconsento')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params.value === false || params.value?.toLowerCase() === 'no' || params.value === 'nonAcconsento')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params.value])?.label || params.value || '-')
                        }
                    }
                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna" ? label : params.value
                        },
                    }
                case fieldTypes.PRICE:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const parsedPrice = params.value?.toString()?.slice(0, params.value?.toString()?.length-2)
                            const parsedCents = params.value?.toString()?.slice(params.value?.toString()?.length-2, params.value?.toString()?.length)
                            const price = `${parsedPrice},${parsedCents} €`
                            return params.value ? price : '0 €'
                        },
                    }
                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }
                case fieldTypes.CUSTOM:
                    return {
                        ...baseField,
                        valueGetter: (params) => params.row.privacy && params.row.fototessera && params.row.certificato
                            ? 'SI': 'NO',
                        renderCell: (params) => (params.value === "SI")
                            ? <Check sx={{color: 'green'}}/>
                            : (params.value === "NO")
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]