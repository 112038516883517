import React, {useContext} from "react";
import {APP_BAR_HEIGHT, customTheme} from "../../theme/customTheme";
import {Box, Button} from "@mui/material";
import {Dashboard, List, Settings} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {GlobalContext} from "../../state/global";
import {setSelectedRoute} from "../../state/global/globalActions";
import {some} from "lodash";
import AuthService from "../../services/auth.service";

const pages = [
    {
        id: 'home',
        label: 'Overview',
        link: '/',
        icon: <Dashboard/>,
        needAdmin: false
    },
    {
        id: 'iscrizioni',
        label: 'Iscrizioni',
        link: '/iscrizioni',
        icon: <List/>,
        subPages: ['/user'],
        needAdmin: false
    },
    {
        id: 'settings',
        label: 'Impostazioni',
        link: '/settings',
        icon: <Settings/>,
        needAdmin: true
    }
];

const CustomMenu = () => {
    const [globalState, dispatch] = useContext(GlobalContext)
    const navigate = useNavigate()

    const currentLocation = (route, subPages) => {
        return globalState?.selectedRoute === route || (some(subPages, function (o) {
            return globalState?.selectedRoute.includes(o)
        }))
    }

    function handleRoutes(route) {
        dispatch(setSelectedRoute(route))
        navigate(route)
    }

    return (
        <Box position={'sticky'}
             top={APP_BAR_HEIGHT}
             display={'flex'}
             justifyContent={'flex-start'}
             alignItems={'center'}
             px={1}
             py={2}
             backgroundColor={customTheme.palette.secondary.main}
             zIndex={10}
        >
            {
                pages.map((page) => (
                    (page.needAdmin && !AuthService.getCurrentUserAdmin())
                        ? null
                        : <Button key={page.id}
                                variant={currentLocation(page.link, page.subPages) ? 'menu-selected' : 'menu'}
                                startIcon={page.icon}
                                onClick={() => handleRoutes(page.link)}>
                            {page.label}
                        </Button>
                ))
            }
        </Box>
    )
}

export default CustomMenu