import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Fab, Paper, TextField, Typography} from "@mui/material";
import CustomPageHeader from "../../components/CustomPageHeader";
import {Add, Save} from "@mui/icons-material";
import AuthService from "../../services/auth.service";
import {GlobalContext} from "../../state/global";
import SettingsService, {forms} from "../../services/settings.service";
import {setAuthState, setGlobalSettings} from "../../state/global/globalActions";
import useAxios from "axios-hooks";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomLoader from "../../components/CustomLoader";
import {orderBy} from "lodash";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";
import CustomFormCard from "../../components/CustomFormCard";
import SetImage from "../../components/SetImage";
import axios from "axios";
import SettingsDialog, {SettingsDialogTypes} from "./SettingsDialog";
import jwt_decode from "jwt-decode";
import {auth} from "../../firebase/clientApp";

const Settings = () => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data, loading: loadingSettings, error: errorSettings}, saveSettings] = useAxios(
        {method: "POST"}, {manual: true}
    )

    const [settings, setSettings] = useState({
        eventName: globalState?.settings?.eventName || '',
        logoUrl: globalState?.settings?.logoUrl || null,
        checkin: false,
        checkInFields: globalState?.settings?.checkInFields || []
    })

    useEffect( () => {
        if (!globalState.authState) {
            auth?.currentUser?.getIdToken()
                .then((token) => {
                    if (token) {
                        const role = jwt_decode(token)?.role
                        dispatch(setAuthState({isAdmin: role === 'ADMIN'}))
                    }
                })
        }
    }, [globalState.authState])

    useEffect(() => {
        if (globalState.settings) {
            setSettings({
                eventName: globalState.settings.eventName,
                checkin: globalState.settings.checkin,
                logoUrl: globalState.settings.logoUrl || null,
                checkInFields: globalState?.settings?.checkInFields || []
            })
        }
    }, [globalState.settings])

    const handleEventName = (event) => {
        setSettings({...settings, eventName: event.target.value});
    };

    const handleCheckInChange = (event) => {
        setSettings({...settings, checkin: event.target.checked});
    };

    const handleSaveSettings = () => {
        saveSettings({
            url: SettingsService.settingsUrl(),
            data: {...settings}
        })
            .then((res) => {
                dispatch(setGlobalSettings({
                    ...globalState.settings,
                    ...settings
                }))
            })
            .catch((err) => console.log("err:", err))
    }

    const handleUpdateImage = async (file) => {
        //console.log("file:", file)
        //setSettings({...settings, logoUrl: file});
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            axios({
                url: `${SettingsService.settingsUrl()}/logo`,
                method: 'POST',
                data: formData,
            })
                .then((res) => {
                    console.log("res:", res)
                    if (res)
                        dispatch(setGlobalSettings({
                            ...globalState.settings,
                            logoUrl: res.data.logoUrl
                        }))
                })
                .catch((err) => console.log("handleUpdateImage --> err:", err))
        }
    }

    const [dialogState, setDialogState] = useState({
        open: false,
        data: null,
        type: null
    })

    const handleOpenDialog = () => {
        setDialogState({open: true, type: SettingsDialogTypes.NEW_DATA_STRUCTURES, data: null})
    }

    const handleCloseDialog = () => {

    }

    return (
        <div>
            {globalState.settings ?
                <div>
                    <CustomBackdrop open={loadingSettings} children={<CustomLoader/>}/>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomPageHeader title={"Impostazioni"} subtitle={'Descrizione impostazioni progetto'}/>
                        <Button variant={'outlined'} color={'accent'}
                                startIcon={<Save/>}
                                onClick={handleSaveSettings}>
                            SALVA
                        </Button>
                    </Box>
                    <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'} component={Paper}
                         variant={'outlined'}>
                        <Typography variant={'h6'}>Generali</Typography>
                        <Box display={'flex'} width={'100%'} alignItems={'center'} flexWrap={'wrap'}>
                            <Box flex={1} mr={2} maxWidth={'350px'}>
                                <SetImage caption={'Immagine Evento / Logo azienda'} tooltip
                                          logoUrl={settings.logoUrl}
                                          handleUpdateImage={handleUpdateImage}
                                />
                            </Box>
                            <Box flex={1} ml={2} maxWidth={'400px'}>
                                <TextField size={'small'} variant={'standard'}
                                           sx={{mt: 1}}
                                           label={'Nome dell\'evento'}
                                           fullWidth
                                           value={settings.eventName}
                                           onChange={handleEventName}>
                                </TextField>
                            </Box>
                        </Box>
                    </Box>
                    {globalState?.authState?.isAdmin &&
                        <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'}
                             component={Paper} variant={'outlined'}>
                            <Typography variant={'h6'}>I form dell'evento</Typography>
                            {
                                <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                                    {
                                        orderBy(Object.values(globalState?.dataStructures || []), 'position')
                                            .map((dataStructure, i) => {
                                                if (dataStructure.id !== forms.CHECKIN.id)
                                                    return <Box key={i} mt={1} mr={2}>
                                                        <CustomFormCard id={dataStructure.id}
                                                                        title={dataStructure.title}
                                                                        description={dataStructure.description}/>
                                                    </Box>
                                            })
                                    }
                                    {globalState?.authState?.isAdmin &&
                                        <CustomTooltip title={'Aggiungi nuovo form'} children={
                                            <IconButton color={'accent'} onClick={handleOpenDialog}>
                                                <Add/>
                                            </IconButton>}/>}
                                    <SettingsDialog dialogState={dialogState} setDialogState={setDialogState}/>
                                </Box>
                            }
                        </Box>}
                    <Fab onClick={handleSaveSettings}
                         color={'primary'}
                         size={"medium"}
                         variant={'action'}>
                        <Save/>
                    </Fab>
                </div> : <Typography>Errore nel caricamento dei dati</Typography>}
            {errorSettings && <Typography>Errore nel caricamento dei dati</Typography>}
        </div>
    )
}

export default Settings